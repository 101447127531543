import yaml from 'js-yaml'

interface Settings {
  basepath: string
  domain: string
  audience: string
  organizationId: string
  clientId: string
  accountsApiUrl: string
  workspaceUrlTemplate: string
  localStorageOrgIdKey: string
  allScopes: string
  cartoVersion: string
  isSnowflakeNativeApp?: boolean
  workspaceUrl?: string
  customTenant?: string
  studentClientId: string
  loginAsStudentKey: string
  supportEmail: string
  supportEmailMailto: string
  documentationUrl: string
  enableTrackJs?: boolean
  hideCartoDatawarehouse?: boolean
  extPartner: string
  redirectAfterLoginUri: string
  autojoinAccountKey: string
  disabledSsoKey: string
  authenticationFlowKey: string
  commitHash: string
}

const settings: Settings = {
  basepath: import.meta.env.VITE_APP_BASE_PATH || '',
  domain: '',
  audience: '',
  organizationId: '',
  clientId: '',
  accountsApiUrl: '',
  workspaceUrlTemplate: '{tenantDomain}',
  allScopes:
    'openid profile email read:current_user update:current_user read:connections write:connections read:maps write:maps read:account admin:account',
  cartoVersion: '',
  localStorageOrgIdKey: 'orgId',
  redirectAfterLoginUri: 'redir',
  authenticationFlowKey: 'authenticationFlow',
  autojoinAccountKey: 'autojoinAcc',
  studentClientId: 'clientId',
  loginAsStudentKey: 'student_account',
  supportEmail: 'support@carto.com',
  supportEmailMailto: 'mailto:support@carto.com',
  documentationUrl: 'https://docs.carto.com',
  extPartner: 'ext_partner',
  disabledSsoKey: 'disabledSso',
  commitHash: ''
}

export function configureApiUrls(tenantDomain: string): void {
  settings.workspaceUrl = settings.workspaceUrlTemplate.replace('{tenantDomain}', tenantDomain)
}

export interface YamlConfig {
  auth0: {
    domain: string
    audience: string
    clientId: string
    organizationId: string
    studentClientId: string
  }
  apis: {
    accountsUrl: string
  }
  workspaceUrlTemplate: string
  customTenant: string
  enableTrackJs?: boolean
}

export async function loadConfig() {
  const configUrl = import.meta.env.VITE_APP_CONFIG_URL || `${import.meta.env.VITE_APP_BASE_PATH || ''}/config.yaml`

  let yamlText
  try {
    const response = await fetch(configUrl)

    if (!response.ok) {
      throw new Error(`File status code ${response.status}.`)
    }
    yamlText = await response.text()
    if (!yamlText) {
      throw new Error(`Empty config file detected.`)
    }
  } catch (e) {
    const msg = e instanceof Error ? e.message : ''
    throw new Error(`Cannot download file ${configUrl}. ${msg}`)
  }

  let config
  try {
    config = yaml.load(yamlText) as YamlConfig
  } catch (e) {
    const msg = e instanceof Error ? e.message : ''
    throw new Error(`Error parsing config file. ${msg}`)
  }

  const { auth0, apis, cartoVersion } = config

  // AUTH0
  settings.domain = auth0.domain
  settings.audience = auth0.audience
  settings.clientId = auth0.clientId
  settings.organizationId = auth0.organizationId
  settings.studentClientId = auth0.studentClientId

  // APIs
  settings.accountsApiUrl = apis.accountsUrl

  // WORKSPACE
  settings.workspaceUrlTemplate = config.workspaceUrlTemplate

  // ON-PREM
  settings.customTenant = config.customTenant || undefined

  // TrackJS
  settings.enableTrackJs = import.meta.env.PROD && config.enableTrackJs

  // CARTO Version
  const selfHosted = cartoVersion.selfHosted
  settings.cartoVersion = selfHosted ? `${selfHosted}` : 'SaaS'
  settings.isSnowflakeNativeApp = cartoVersion.snowflakeNativeApp || false

  // CARTO Datawarehouse
  settings.hideCartoDatawarehouse = !config.cartoDwEnabled
}

export default settings
